import axios from "axios";
import authHeader from "./auth.header";

const authenticate = () => {
  return axios.post("/api/authentication");
};

const restaurants = () => {
  return axios.get("/api/restaurants", { headers: authHeader() });
};

const getMenuItems = () => {
  return axios.get("/api/get-menu", { headers: authHeader() });
};

const getTables = () => {
  return axios.get("/api/table", { headers: authHeader() });
};

const createGuestUser = (userData) => {
  return axios.post("/api/create-guest", userData, { headers: authHeader() });
};

const priceCheck = (pricecheck) => {
  return axios.post("/api/pricecheck", pricecheck, { headers: authHeader() });
};

const checkout = (checkoutData) => {
  return axios.post("/api/checkout", checkoutData, { headers: authHeader() });
};

const getAllPaymentMethod = (guest_id) => {
  return axios.post("/api/stripe-payment-methods", guest_id, {
    headers: authHeader(),
  });
};

const getOrderDetails = (Data) => {
  return axios.post("/api/get-order-details", Data, {
    headers: authHeader(),
  });
};

const getOneOrderDetails = (order_id) => {
  console.log(order_id, "order_iddd");
  return axios.post("/api/get-one-order-details", order_id, {
    headers: authHeader(),
  });
};

export default {
  authenticate,
  restaurants,
  getMenuItems,
  getTables,
  createGuestUser,
  priceCheck,
  checkout,
  getAllPaymentMethod,
  getOrderDetails,
  getOneOrderDetails,
};
