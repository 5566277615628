import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

// Default theme
import "@splidejs/react-splide/css";

// or only core styles
import "@splidejs/react-splide/css/core";

// component
// import EditMenuDetailsPopup from "./EditMenuDetailsPopup";
// import useOutsideClick from "../../components/useOutsideClick";
import Services from "../../Services/auth.api";
import authRestaurant from "../../Services/auth.restaurants";
import {
  decrementQuantity,
  loginPageState,
  incrementQuantity,
} from "../../redux/cart.slice";

// Images
import ChevronRight from "@material-ui/icons/ChevronRight";

// icons
import Close from "@material-ui/icons/Close";
import ShoppingCart from "@material-ui/icons/ShoppingCartOutlined";

export default function AddCartDrawer(props) {
  const [showAccordian, setAccordianShow] = useState(false);
  const [showRemoveLoader, setRemoveLoader] = useState("");
  const [showChoice, setChoice] = useState("");
  const [showCartDetails, setCartDetails] = useState([]);
  const [showOrderType, setOrderType] = useState("");
  const [showTotalPrice, setTotalPrice] = useState("");
  const [showTotalTax, setTotalTax] = useState("");
  const [showAddToCartLoader, setAddToCartLoader] = useState("");
  // const [showMenuDetailsPopup, setMenuDetailsPopup] = useState("");
  const [showLoginId, setLoginId] = useState("");
  const [showLoaderCart, setLoaderCart] = useState(false);
  const [showUpsellGroupData, setUpsellGroupData] = useState([]);
  const [showUpsellGroupItem, setUpsellGroupItem] = useState([]);
  const [showRestaurantId, setRestaurantId] = useState();
  const [showMenuDetails, setMenuDetails] = useState([]);
  const ref = useRef();
  const router = useRouter();

  const dispatch = useDispatch();

  useEffect(() => {
    var get = localStorage.getItem("CartDetails");
    var orderType = localStorage.getItem("deliveryType");
    const guestData = localStorage.getItem("guest_id");
    setLoginId(guestData);
    if (orderType !== null) {
      setOrderType(JSON.parse(orderType).when);
    }
    console.log(JSON.parse(get), "Gsset");
    const CartData = JSON.parse(get);
    if (CartData !== null) {
      setCartDetails(JSON.parse(get));
      const TotalPrice = CartData.reduce(
        (accumulator, current) =>
          accumulator + current.priceDetails[0].TotalPrice,
        0
      );
      setTotalPrice(TotalPrice);
      const TotalTax = CartData.reduce(
        (accumulator, current) =>
          accumulator + current.priceDetails[0].TaxPrice,
        0
      );
      setTotalTax(TotalTax);
    } else {
      setCartDetails([]);
    }
  }, [props.onClickback]);

  useEffect(() => {
    const restaurantdetails = localStorage.getItem("restaurantDetails");
    console.log(restaurantdetails, "restaurantdetails");
    if (restaurantdetails !== null) {
      var restaurant_id = JSON.parse(restaurantdetails).restaurant_directus_id;
      var restaurant_id_bbot = JSON.parse(restaurantdetails).restaurant_id;
      if (restaurant_id !== null) {
        const bodyData = {
          restaurant_id: restaurant_id,
        };
        authRestaurant.getAllMenus(bodyData).then((response) => {
          console.log(response, "response Data");
          if (response.data.data.lenght !== 0) {
            setMenuDetails(response.data.data[0]);
          }
        });
      }

      setRestaurantId(restaurant_id_bbot);
      authRestaurant.GetUpsellGroup().then((response) => {
        if (response.data.data.length !== 0) {
          console.log(response, "response Cart page");
          setMenuDetails(response.data.data[0]);
          const UpsellData = response.data.data.filter((MenuItemData) => {
            console.log(restaurant_id, "restaurant_id");
            console.log(
              MenuItemData.restaurant_list,
              "MenuItemData.restaurant_list"
            );
            return restaurant_id === MenuItemData.restaurant_list;
          });
          console.log(UpsellData, "UpsellData");
          setUpsellGroupData(UpsellData);
          if (UpsellData.length !== 0) {
            UpsellData.map((menuData) => {
              const bodyData = {
                select_menu_items: menuData.select_menu_items,
              };
              authRestaurant.getUpsellMenu(bodyData).then((responseData) => {
                if (responseData.data.data.length !== 0) {
                  setUpsellGroupItem((prevSelected) => [
                    ...prevSelected,
                    {
                      upsell_id: menuData.id,
                      menuItem: responseData.data.data,
                    },
                  ]);
                }
                console.log(responseData, "responseData");
              });
            });
          }
        }
      });
    }
  }, []);

  // function getUpsellMenuData(menuArray,upsell_id){
  //   const bodyData = {
  //     select_menu_items: menuArray,
  //   };
  //   authRestaurant.getUpsellMenu(bodyData).then((responseData) => {
  //     if(responseData.data.data.length !== 0){
  //       // if()
  //       setUpsellGroupItem((prevSelected) => [...prevSelected, {upsell_id:upsell_id , menuItem:responseData.data.data}]);
  //       // setUpsellGroupItem([...showUpsellGroupItem,{upsell_id:upsell_id , menuItem:responseData.data.data}])
  //     }
  //     console.log(responseData,"responseData")
  //   });
  // }

  console.log(showUpsellGroupItem, "showUpsellGroupItem");

  const onClickClose = () => {
    document.body.classList.remove("Add-cart-body");
    return props.onClickback(false);
  };

  const onClickAccordian = (id) => {
    setChoice(id);
  };

  const onClickRemoveCartIcon = (CartItemId, Quantity, index) => {
    setRemoveLoader(CartItemId);
    const addToCartData = [];
    showCartDetails.filter((data, cartIndex) => {
      if (data.menuItem[0].source_id !== CartItemId || cartIndex !== index) {
        addToCartData.push(data);
      }
      // return (
    });
    dispatch(decrementQuantity(Quantity));
    console.log(addToCartData, "addToCartData");
    setTimeout(() => {
      setRemoveLoader("");
      setCartDetails(addToCartData);
      const TotalPrice = addToCartData.reduce(
        (accumulator, current) =>
          accumulator + current.priceDetails[0].TotalPrice,
        0
      );
      setTotalPrice(TotalPrice);
      const TotalTax = addToCartData.reduce(
        (accumulator, current) =>
          accumulator + current.priceDetails[0].TaxPrice,
        0
      );
      setTotalTax(TotalTax);

      toast.success(`${Quantity} item removed from cart.`, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }, 200);
    localStorage.setItem("CartDetails", JSON.stringify(addToCartData));
    return props.onClickback(true);
  };

  const onClickStartOrder = () => {
    return props.onClickback(false);
  };

  // onClick menu items
  // const onClickEditCartItem = (menu_Id, variantLength) => {
  //   // setSearchResult([])
  //   // console.log(variantLength, "variantLength");
  //   // console.log(menu_Id, "menu_Id");
  //   // if (variantLength !== 0) {
  //   //   router.push(`/basket/product/${menu_Id}`);
  //   // } else {
  //   //   document.body.classList.add("sr-body");
  //   //   setMenuDetailsPopup(menu_Id);
  //   // }
  // };

  // const onClickClosePopup = (e) => {
  //   setMenuDetailsPopup(e);
  // };

  // outsideclick function

  // useOutsideClick(ref, (e) => {
  //   if (showMenuDetailsPopup) {
  //     document.body.classList.remove("sr-body");
  //     setMenuDetailsPopup("");
  //   }
  // });

  function getHashForLineItem(menuItemId, modifiers, specialInstructions) {
    let stringToHash = menuItemId;

    function addModifiersToHashString(modifiersToAdd) {
      console.log(modifiersToAdd, "modifiersToAdd");
      const sortBy = (key) => {
        return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
      };
      Array.from(modifiersToAdd)
        .sort(sortBy("modifier_id"))
        .forEach((modToAdd) => {
          console.log(modToAdd, "modToAdd.modifier_id");
          stringToHash += "_" + modToAdd.modifier_bbot_id;
        });
    }

    addModifiersToHashString(modifiers);
    stringToHash += specialInstructions;

    const hashCode = (s) =>
      s.split("").reduce((currentHash, nextChar) => {
        currentHash = (currentHash << 5) - currentHash + nextChar.charCodeAt(0);
        return currentHash & currentHash;
      }, 0);

    return hashCode(stringToHash);
  }

  // onClick Chekout button in Cart

  const onClickCheckout = () => {
    setLoaderCart(true);
    if (showLoginId !== undefined && showLoginId !== "") {
      console.log("hello");
      var get = localStorage.getItem("CartDetails");
      const table_id = localStorage.getItem("table_id");
      const fulfillment_method = localStorage.getItem("fulfillment_method");
      const CartData = JSON.parse(get);
      const CartBodyData = [];
      const data = [];

      const testData = CartData.map((CartDetails, index) => {
        console.log(CartDetails, "CartDetails");
        CartDetails.menuItem[0].source_id;
        const dataId = getHashForLineItem(
          CartDetails.menuItem[0].source_id,
          CartDetails.modifiers,
          CartDetails.specialInstruction[0].specialInstruction
        );
        console.log(data, "data");
        data.push(dataId);
        const modifierheadingidArr = [];

        CartDetails.modifiers.filter((ModifierData) => {
          if (
            modifierheadingidArr.indexOf(ModifierData.modifier_heading_id) == -1
          ) {
            modifierheadingidArr.push(ModifierData.modifier_heading_id);
          }
        });
        const modiFierArray = [];
        const modiFierArrayMain = [];

        var test = modifierheadingidArr.map((HeadingArr) =>
          CartDetails.modifiers.filter((ModifierData) => {
            if (HeadingArr === ModifierData.modifier_heading_id) {
              var testdata = {
                mods: {},
                menu_item_id: ModifierData.source_id,
                name_for_customer: ModifierData.name_for_guest,
              };
              return testdata;
            }
          })
        );
        console.log(test, "modiFierArray");

        console.log(data, "datadatadatadatadatadata");

        var obj = {};
        for (var i = 0; i < modifierheadingidArr.length; i++) {
          console.log(test[i], "hgdhgdjhgsdhjd");
          //  if(test[i].)
          var comminData = test[i].map((dd) => {
            return {
              modifiers: {},
              menu_item_id: dd.source_id,
            };
          });
          obj[modifierheadingidArr[i]] = comminData;
        }
        console.log(obj, "obj");
        // {"cart":[{"line_item_id":1538171917,"menu_id":"2f5c81de-e8c7-4aec-8a6d-97fbf57e71d4","menuItemId":"bd241bd3-c386-48eb-b759-225a1624be92","modifiers":{},"quantity":1,"discounts":[],"special_instructions":""}],"promo_codes":[],"table_id":"bc763d7e-8a8b-453d-a50d-8c0e0b632786","fulfillment_method":"patron_pickup","guest_id":"1712"}

        // {"cart":[{"line_item_id":-1361490981,"menuItemId":"bd241649-e56e-456f-a75a-c9bc380b33d3","modifiers":{},"quantity":1,"discounts":[],"special_instructions":""}],"promo_codes":[],"table_id":"bc763d7e-8a8b-453d-a50d-8c0e0b632786","fulfillment_method":"patron_pickup","guest_id":"1712"}

        // {"cart":[{"line_item_id":1538171917,"menu_id":"436","menuItemId":"bd241bd3-c386-48eb-b759-225a1624be92","modifiers":{},"quantity":1,"discounts":[],"special_instructions":""}],"promo_codes":[],"table_id":"bc763d7e-8a8b-453d-a50d-8c0e0b632786","fulfillment_method":"patron_pickup","guest_id":"1712"}
        console.log(
          CartDetails.MainMenuDetails[0],
          "CartDetails.MainMenuDetails[0]"
        );
        CartBodyData.push({
          // "restaurant_id": CartDetails.MainMenuDetails[0].restaurant_id,
          line_item_id: data[index],
          menu_id: CartDetails.MainMenuDetails[0].menu_id,
          menuItemId: CartDetails.menuItem[0].source_id,
          modifiers: obj,
          quantity: CartDetails.priceDetails[0].Quantity,
          discounts: [],
          special_instructions:
            CartDetails.specialInstruction[0].specialInstruction,
        });
      });

      var object = {};
      for (var i = 0; i < CartBodyData.length; i++) {
        object[data[i]] = CartBodyData[i];
      }
      const bodyData = {
        cart: CartBodyData,
        promo_codes: [],
        table_id: table_id,
        fulfillment_method: fulfillment_method,
        guest_id: showLoginId,
      };
      console.log(bodyData, "bodyData");

      Services.priceCheck(bodyData)
        .then((response) => {
          console.log(response, "Sdsdsd");
          if (response.status === 200) {
            setLoaderCart(false);
            // router.push(`/orderPayment?${}`)
            router.push({ pathname: "/orderPayment" });
            dispatch(loginPageState(false));
            console.log(response, "data");
            return props.onClickback(false);
          } else {
            setLoaderCart(false);
            console.log(response.status, "data");
          }
        })
        .catch((error) => {
          // Handle error.
          dispatch(loginPageState(false));
          if (error.response) {
            setLoaderCart(false);
            toast.error(error.response.data.error_message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          console.log("An error occurred:", error.response);
        });
    }
  };

  const onClickLogin = () => {
    router.push(`/checkoutinfo`);
    return props.onClickback(false);
  };

  const replaceImgWithError = (e) => {
    e.target.onerror = null;
    e.target.src =
      "https://static.staging.bbot.menu/customer/d8425ceb-d0fd-4e3f-abf2-a1db3f02f15f/menuItem/Jqr5vTVfSMOEYiTwqXuICQ";
  };

  const onClickMenuDetails = (sourceId, variantLength, mainMenuID) => {
    console.log(variantLength, "variantLength");
    console.log(sourceId, "menu_Id");
    console.log(mainMenuID, "mainMenuID");
    if (variantLength !== 0) {
      var showMainMenuId;
      console.log(showRestaurantId, "showRestaurantId");
      showMenuDetails.menuItem.filter((MenuITems) => {
        if (MenuITems.source_id === sourceId) {
          console.log(MenuITems.menu_id, "MenuITems.id");
          showMenuDetails.mainMenu.filter((itemsData) => {
            console.log(itemsData.id, "MenuITems.id");
            if (itemsData.id === MenuITems.menu_id) {
              showMainMenuId = itemsData.source_id;
            }
          });
        }
      });
      router.push(
        `/viewmenu/${showRestaurantId}/product/${mainMenuID}/${showMainMenuId}`
      );
      return props.onClickback(false);
    } else {
      dispatch(incrementQuantity(1));
      setLoaderCart(sourceId);
      console.log(sourceId, "hello");
      const selectedData = showUpsellGroupItem[0].menuItem.filter((items) => {
        return items.source_id === sourceId;
      });

      const showMainMenuBBOTID = showMenuDetails.mainMenu.filter(
        (MenuITems) => {
          return MenuITems.id === selectedData[0].menu_id;
        }
      );

      const menuItemData = selectedData[0].tax_cents;
      const showAddToCartPrice = selectedData[0].pretax_cents * 1;

      const TotalTaxData = menuItemData * 1;

      var cartDetails = [
        {
          modifiers: [],
          menuItem: selectedData,
          MainMenuDetails: [
            {
              restaurant_id: showRestaurantId,
              menu_id: showMainMenuBBOTID[0].source_id,
            },
          ],
          priceDetails: [
            {
              Quantity: 1,
              TotalPrice: showAddToCartPrice,
              TaxPrice: TotalTaxData,
            },
          ],
          specialInstruction: [{ specialInstruction: "", madeFor: "" }],
        },
      ];

      if (localStorage.getItem("CartDetails") === null) {
        localStorage.setItem("CartDetails", JSON.stringify(cartDetails));
      } else {
        // Get the existing data
        console.log(cartDetails, "cartDetails");
        var existing;
        existing = JSON.parse(localStorage.getItem("CartDetails")) || [];
        console.log(existing, "existing");
        if (
          existing.some(function (o) {
            return o.menuItem[0].id === cartDetails[0].menuItem[0].id;
          })
        ) {
          let flag = 0;
          let status = 0;
          existing.map((item, index) => {
            if (status === 0) {
              console.log("Iff calling");
              if (item.menuItem[0].id === cartDetails[0].menuItem[0].id) {
                console.log("Iff calling");
                if (cartDetails[0].modifiers.length !== 0) {
                  if (
                    cartDetails[0].modifiers.length === item.modifiers.length
                  ) {
                    let md = 0;
                    cartDetails[0].modifiers.map((modifier1, i) => {
                      if (
                        item.modifiers.some(function (modifier) {
                          return modifier.id === modifier1.id;
                        })
                      ) {
                        md++;
                      }
                    });

                    if (md === 0) {
                      flag = 1;
                    } else {
                      if (item.modifiers.length === md) {
                        // item.priceDetails[0].Quantity = item.priceDetails[0].Quantity+cartDetails[0].priceDetails[0].Quantity;
                        // item.priceDetails[0].TaxPrice = item.priceDetails[0].TaxPrice+cartDetails[0].priceDetails[0].TaxPrice;
                        // item.priceDetails[0].TotalPrice = item.priceDetails[0].TotalPrice+cartDetails[0].priceDetails[0].TotalPrice;
                        // status = 1;
                        if (
                          cartDetails[0].specialInstruction.length === 0 &&
                          item.specialInstruction.length === 0
                        ) {
                          item.priceDetails[0].Quantity =
                            item.priceDetails[0].Quantity +
                            cartDetails[0].priceDetails[0].Quantity;
                          item.priceDetails[0].TaxPrice =
                            item.priceDetails[0].TaxPrice +
                            cartDetails[0].priceDetails[0].TaxPrice;
                          item.priceDetails[0].TotalPrice =
                            item.priceDetails[0].TotalPrice +
                            cartDetails[0].priceDetails[0].TotalPrice;
                          status = 1;
                        } else {
                          if (
                            cartDetails[0].specialInstruction[0].specialInstruction.toLowerCase() ===
                            item.specialInstruction[0].specialInstruction.toLowerCase()
                          ) {
                            item.priceDetails[0].Quantity =
                              item.priceDetails[0].Quantity +
                              cartDetails[0].priceDetails[0].Quantity;
                            item.priceDetails[0].TaxPrice =
                              item.priceDetails[0].TaxPrice +
                              cartDetails[0].priceDetails[0].TaxPrice;
                            item.priceDetails[0].TotalPrice =
                              item.priceDetails[0].TotalPrice +
                              cartDetails[0].priceDetails[0].TotalPrice;
                            status = 1;
                          } else {
                            flag = 1;
                          }
                        }
                      } else {
                        flag = 1;
                      }
                    }
                  } else {
                    flag = 1;
                  }
                } else if (
                  cartDetails[0].modifiers.length === 0 &&
                  item.modifiers.length === 0
                ) {
                  if (
                    cartDetails[0].specialInstruction.length === 0 &&
                    item.specialInstruction.length === 0
                  ) {
                    item.priceDetails[0].Quantity =
                      item.priceDetails[0].Quantity +
                      cartDetails[0].priceDetails[0].Quantity;
                    item.priceDetails[0].TaxPrice =
                      item.priceDetails[0].TaxPrice +
                      cartDetails[0].priceDetails[0].TaxPrice;
                    item.priceDetails[0].TotalPrice =
                      item.priceDetails[0].TotalPrice +
                      cartDetails[0].priceDetails[0].TotalPrice;
                    status = 1;
                  } else {
                    if (
                      cartDetails[0].specialInstruction[0].specialInstruction.toLowerCase() ===
                      item.specialInstruction[0].specialInstruction.toLowerCase()
                    ) {
                      item.priceDetails[0].Quantity =
                        item.priceDetails[0].Quantity +
                        cartDetails[0].priceDetails[0].Quantity;
                      item.priceDetails[0].TaxPrice =
                        item.priceDetails[0].TaxPrice +
                        cartDetails[0].priceDetails[0].TaxPrice;
                      item.priceDetails[0].TotalPrice =
                        item.priceDetails[0].TotalPrice +
                        cartDetails[0].priceDetails[0].TotalPrice;
                      status = 1;
                    } else {
                      flag = 1;
                    }
                  }
                }
              }
            }
          });

          if (status === 0) {
            if (flag === 1) {
              existing.push(...cartDetails);
            }
          }
        } else {
          console.log("elsemain");
          existing.push(...cartDetails);
        }

        console.log(existing, "existing11");
        // If no existing data, create an array
        // Otherwise, convert the localStorage string to an array
        //  existing.push(...cartDetails);
        // Save back to localStorage
        localStorage.setItem("CartDetails", JSON.stringify(existing));
      }

      toast.success(`1 item added to cart.`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //
      setTimeout(() => {
        setLoaderCart("");
      }, 500);
    }
  };

  return (
    <>
      <div className="flex flex-col h-full">
        {/* header */}
        <div className="p-3 lg:p-4 flex items-center justify-between bg-gray-8 border-b border-gray-9 shadow-4xl">
          <div>
            <h3 className="text-17xl lg:text-xl pt-px pb-0-2 font-normal ">
              Your Order
            </h3>
            <p className="text-12xl lg:text-sm font-normal text-black-6  ">
              {showOrderType}
            </p>
          </div>
          <div onClick={onClickClose}>
            <Close style={{ color: "#111111", fontSize: "24px" }} />
          </div>
        </div>

        <div className="fd-addcard-details flex flex-col overflow-auto relative  grow">
          {/* show while no have order */}
          {showCartDetails.length === 0 ? (
            <div className="mt-px pt-25 px-4 pb-4 text-center">
              <ShoppingCart style={{ color: "#111111", fontSize: "48px" }} />
              <p className="mt-3 text-22/2xl text-black-1 font-semibold">
                Your cart is empty
              </p>
              <button
                type="button"
                className="mt-10 py-4 px-3 rounded-3 w-full text-white text-lg font-semibold bg-darkGreen hover:bg-lightGreen hover:shadow-4xl transition duration-300"
                onClick={onClickStartOrder}
              >
                Start Your Order
              </button>
            </div>
          ) : (
            <>
              <div className="fd-addcard-food-detais flex flex-col overflow-auto relative grow">
                <div className="grow flex flex-col ">
                  {/* 1 */}
                  {showCartDetails.lenght !== 0 &&
                    showCartDetails.map((cartData, index) => (
                      // console.log(cartData,"test")
                      <div
                        key={index}
                        className="p-4 grow flex border-t border-gray-9"
                      >
                        <div className="mr-2">
                          <p className="text-sm lg:text-base font-normal black-3">
                            {cartData.priceDetails[0].Quantity}
                          </p>
                        </div>
                        <div className="grow">
                          <div
                            className="flex items-center"
                            // onClick={() =>
                            //   onClickEditCartItem(
                            //     cartData.menuItem[0].source_id,
                            //     cartData.menuItem[0].modifier_ids.length
                            //   )
                            // }
                          >
                            <div className="mr-2 grow ">
                              <a className="w-full flex justify-between items-center">
                                <p className="text-black-3 font-semibold text-sm lg:text-base  underline decoration-2 underline-offset-4 decoration-black-3/2 block transition duration-300 hover:text-black-2 hover:decoration-black-2">
                                  {cartData.menuItem[0].name_for_guest}
                                </p>
                                <p className="text-sm lg:text-base font-normal text-black-1">
                                  US$
                                  {(
                                    cartData.priceDetails[0].TotalPrice / 100
                                  ).toFixed(2)}
                                </p>
                              </a>
                            </div>

                            <button
                              className="flex items-center"
                              onClick={() =>
                                onClickRemoveCartIcon(
                                  cartData.menuItem[0].source_id,
                                  cartData.priceDetails[0].Quantity,
                                  index
                                )
                              }
                              disabled={
                                showRemoveLoader !==
                                cartData.menuItem[0].source_id
                                  ? false
                                  : true
                              }
                            >
                              <Close
                                style={{
                                  color: "#ffffff",
                                  fontSize: "16px",
                                  background: "#111111",
                                  borderRadius: "30px",
                                }}
                              />
                            </button>
                          </div>
                          {/* food categories */}
                          {cartData.modifiers.length !== 0 && (
                            <div>
                              <ul className="mt-1 pt-1 mb-2 text-12xl lg:text-sm font-normal text-black-6">
                                {cartData.modifiers.map((modifiers, index) =>
                                  index < 3 ? (
                                    <>
                                      <li key={index}>
                                        {modifiers.name_for_guest}
                                      </li>
                                    </>
                                  ) : (
                                    showChoice ===
                                      cartData.menuItem[0].source_id && (
                                      <li key={index}>
                                        {modifiers.name_for_guest}
                                      </li>
                                    )
                                  )
                                )}

                                <a className="text-sm lg:text-sm font-semibold text-black-3">
                                  {cartData.modifiers.length > 3 &&
                                  showChoice !==
                                    cartData.menuItem[0].source_id ? (
                                    <span
                                      className="block accordion"
                                      onClick={() =>
                                        onClickAccordian(
                                          cartData.menuItem[0].source_id
                                        )
                                      }
                                    >
                                      +
                                      <span>
                                        {cartData.modifiers.length - 3}
                                      </span>{" "}
                                      more choices
                                    </span>
                                  ) : (
                                    cartData.modifiers.length > 3 &&
                                    showChoice ===
                                      cartData.menuItem[0].source_id && (
                                      <span
                                        className="block hide-choice accordion"
                                        onClick={() =>
                                          onClickAccordian(
                                            !cartData.menuItem[0].source_id
                                          )
                                        }
                                      >
                                        -hide choices
                                      </span>
                                    )
                                  )}
                                </a>
                              </ul>
                            </div>
                          )}
                          <p>{cartData.specialInstruction[0].madeFor}</p>
                          <p>
                            {cartData.specialInstruction[0].specialInstruction}
                          </p>
                        </div>
                      </div>
                    ))}
                  <div className="p-4 border-t border-gray-9">
                    {/* accordian */}
                    <button
                      type="button"
                      className="w-full flex items-center justify-between"
                      onClick={() => setAccordianShow(!showAccordian)}
                    >
                      <div>
                        <h3 className="text-base lg:text-lg text-black-3 font-semibold">
                          May We Suggest
                        </h3>
                      </div>
                      <div>
                        <ChevronRight
                          className={`sr-rotate-arrow ${
                            showAccordian ? "sr-rotate-90" : ""
                          }`}
                          style={{ color: "#111111", fontSize: "24px" }}
                        />
                      </div>
                    </button>

                    {/* accordian details */}

                    <div
                      className={`sr-accordian cstm-sr-sr-accordian ${
                        showAccordian ? "" : "open"
                      }`}
                    >
                      {/* 1st content */}

                      {showUpsellGroupData.length !== 0 &&
                        showUpsellGroupData.map((upsellData, index) => (
                          <div
                            className="mt-4 sr-cstm-add-card-slider"
                            key={index}
                          >
                            {showUpsellGroupItem.length !== 0 &&
                              showUpsellGroupItem
                                .filter(
                                  (menuItemData) =>
                                    menuItemData.upsell_id === upsellData.id
                                )
                                .map((Menulist) => (
                                  <Splide
                                    hasTrack={false}
                                    // aria-label="..."
                                    aria-label="Placeholder Example"
                                    options={{
                                      type: "slide",
                                      role: "region",
                                      rewind: false,
                                      width: "100%",
                                      // arrows: true,
                                      gap: "0.5rem",
                                      start: 0,
                                      perPage: 4,
                                      perMove: 1,
                                      pagination: false,
                                      drag: false,
                                      snap: true,
                                      dragMinThreshold: {
                                        mouse: 0,
                                        touch: 10,
                                      },
                                      // padding: { left: 10, right: 20 },
                                      // padding: { left: "1rem", right: "2rem" },
                                      breakpoints: {
                                        1600: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 3.2,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        1400: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 2.75,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        1200: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 3.2,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        1024: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 2.5,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        941: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 2.9,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        768: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 2.5,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        740: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 5.2,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        600: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 4.2,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        500: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 3.5,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        400: {
                                          drag: "free",
                                          mediaQuery: "min",
                                          perPage: 2.5,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                        367: {
                                          drag: "free",
                                          mediaQuery: "max",
                                          perPage: 2,
                                          perMove: 1,
                                          snap: true,
                                          updateOnMove: true,
                                        },
                                      },
                                    }}
                                  >
                                    <div className="mb-4 lg:mb-5 flex items-center">
                                      <h4 className="text-sm grow lg:text-base font-semibold text-black-1">
                                        {upsellData.group_name}
                                      </h4>
                                      <div className="flex items-center relative sr-cstm-mian-arrow">
                                        <div className="splide__arrows">
                                          <button className="splide__arrow splide__arrow--prev">
                                            <ChevronRight
                                              className="mr-4 rotate-180"
                                              style={{
                                                color: "#414142",
                                                fontSize: "24px",
                                              }}
                                            />
                                          </button>
                                          <button className="splide__arrow splide__arrow--next">
                                            <ChevronRight
                                              className=""
                                              style={{
                                                color: "#414142",
                                                fontSize: "24px",
                                              }}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <SplideTrack>
                                      {/* slider start here */}
                                      {Menulist.menuItem.map(
                                        (list, index, arr) => (
                                          <SplideSlide>
                                            <div
                                              key={index}
                                              data-id={
                                                index === 0 ? arr.length : ""
                                              }
                                              className={`sr-menu-item-div menu-item-${index} `}
                                              id={`menu-item-${index}`}
                                            >
                                              <div className="border border-gray-9 shadow-4xl h-full">
                                                <div
                                                  onClick={() =>
                                                    onClickMenuDetails(
                                                      list.source_id,
                                                      list.modifier_ids.length,
                                                      list.id
                                                    )
                                                  }
                                                  className="p-2 cursor-pointer h-full flex flex-col"
                                                >
                                                  <div className="">
                                                    {list.display_on_front ===
                                                    true ? (
                                                      <img
                                                        src={`${process.env.NEXT_PUBLIC_DIRECTUS_API_URL}/assets/${list.display_menu_image}.svg`}
                                                        layout="responsive"
                                                        objectFit="cover"
                                                        quality={100}
                                                        width="100%"
                                                        height="100%"
                                                        alt="Image not found"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={list.image_url}
                                                        layout="responsive"
                                                        objectFit="cover"
                                                        quality={100}
                                                        width="100%"
                                                        height="100%"
                                                        alt="Image not found"
                                                        onError={
                                                          replaceImgWithError
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="pt-1 grow flex justify-between flex-col">
                                                    <h5 className="mb-2 text-black-3 text-sm md:text-base grow font-bold normal-case">
                                                      {list.display_on_front ===
                                                      true
                                                        ? list.display_name_for_guest
                                                        : list.name_for_guest}
                                                    </h5>
                                                    <h6 className="m-0 font-normal text-black-3 text-sm md:text-base">
                                                      {list.price_string}
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </SplideSlide>
                                        )
                                      )}
                                    </SplideTrack>
                                  </Splide>
                                ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="w-full pt-4 px-4 bg-neutral-100">
                  <div className="text-black-1 text-sm lg:text-base font-normal mb-2 flex items-center justify-between">
                    <h6 className="text-left">Subtotal</h6>
                    <p className="text-right">
                      ${(showTotalPrice / 100).toFixed(2)}
                    </p>
                  </div>
                  <div className="text-black-1 text-sm lg:text-base font-normal lg:mb-2 flex items-center justify-between">
                    <h6 className="text-left">Estimated Tax</h6>
                    <p className="text-right">
                      ${(showTotalTax / 100).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-4 fd-addcard-btn relative bg-neutral-100 block">
                {showLoginId !== null && showLoginId !== "" ? (
                  <button
                    onClick={onClickCheckout}
                    className="w-full h-60 py-2 pl-5 pr-3 rounded-3 bg-darkGreen hover:bg-lightGreen text-white text-base lg:text-lg font-bold flex items-center justify-between hover:shadow-4xl"
                  >
                    Checkout
                    <span className="bg-black-5 px-1 py-2 rounded-3">
                      US${((showTotalPrice + showTotalTax) / 100).toFixed(2)}
                    </span>
                  </button>
                ) : (
                  <button
                    onClick={onClickLogin}
                    className="w-full h-60 py-2 pl-5 pr-3 rounded-3 bg-darkGreen hover:bg-lightGreen text-white text-base lg:text-lg font-bold flex items-center justify-between hover:shadow-4xl"
                  >
                    Login to checkout
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* menu Details popup */}

      {/* <div
        ref={ref}
        className={`modelbox ${showMenuDetailsPopup ? "open" : ""}`}
      >
        {showMenuDetailsPopup ? (
          <EditMenuDetailsPopup
            clickEvent={onClickClosePopup}
            menuId={showMenuDetailsPopup}
          />
        ) : (
          ""
        )}
      </div> */}
      {/* Show Loader */}
      <div
        className={`absolute top-0 z-10 left-0 h-screen bg-gray-6 w-full  modelbox ${
          showLoaderCart ? "open" : ""
        }`}
      >
        <div className="  flex h-full w-full justify-center items-center ">
          <div className="rounded-2xl p-8 bg-white ">
            <div className="sr-location-loder"></div>
          </div>
        </div>
      </div>
    </>
  );
}
